@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;600&display=swap);
/* https://fonts.google.com/specimen/Montserrat */

/* https://fonts.google.com/noto/specimen/Noto+Serif */

@font-face {
  font-family: 'opendyslexic';
  src: local('opendyslexic'), url(/static/media/opendyslexic-regular-webfont.97c7d53e.woff2) format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'opendyslexic';
  src: local('opendyslexic'), url(/static/media/opendyslexic-bold-webfont.fa9c99d6.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

