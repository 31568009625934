html, body {
  height: 100%;
  width: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
