/* https://fonts.google.com/specimen/Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

/* https://fonts.google.com/noto/specimen/Noto+Serif */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500;600&display=swap');

@font-face {
  font-family: 'opendyslexic';
  src: local('opendyslexic'), url('./theme/fonts/opendyslexic-regular-webfont.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'opendyslexic';
  src: local('opendyslexic'), url('./theme/fonts/opendyslexic-bold-webfont.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
